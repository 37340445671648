
import { getIndustriesList } from '@/api/industriesAndLocation';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseSelect from '@/components/baseComponents/BaseSelect.vue';
import BaseCertificates from '@/components/baseComponents/BaseCertificates.vue';
import BaseCirclePreloader from '@/components/baseComponents/BaseCirclePreloader.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { getNextPage } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    TitleDescriptionUniversal,
    BaseCirclePreloader,
    BaseButton,
    BaseSelect,
    BaseCertificates,
  },
  computed: {
    ...mapGetters('steps', ['isPreviousPageExist']),
  },
})
export default class IndustryPage extends Vue {
  public isPreviousPageExist!: boolean;

  isPreloaderShow = false;
  isIndustriesLoading = false;
  optionsIndustries = [];
  errors = {
    industry: false,
  };

  get industry(): string {
    return this.$store.state.user.industryForBusinessValuation;
  }
  set industry(value: string) {
    this.$store.commit('user/setIndustryForBusinessValuation', value);
  }
  get correctFields(): boolean {
    return Boolean(this.industry);
  }

  checkField(fieldName: string): void {
    if (fieldName === 'industry') this.errors.industry = Boolean(!this.industry);
  }
  async assignmentIndustries(): Promise<void> {
    try {
      this.isIndustriesLoading = true;
      const optionsIndustriesArr = ((await getIndustriesList()) as any).data;
      this.optionsIndustries = this.industriyByLang(optionsIndustriesArr, 'en-US');
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isIndustriesLoading = false;
    }
  }
  industriyByLang(industriesArr: any, lang: string): never[] {
    return industriesArr.map((item: any) => {
      const name = JSON.parse(item.name)[lang];
      return { ...item, name };
    });
  }
  async nextButtonAction(): Promise<void> {
    this.$router.push({ name: getNextPage() });
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  created(): void {
    this.assignmentIndustries();
  }
}
